<template>
  <div class="column over-hidden full">
    <div class="header">
      <div @click="cancel" style="cursor: pointer">
        <ArrowLeftOutlined />
        {{ currentId ? "编辑模板" : "新增模板" }}
      </div>
    </div>
    <div class="flex1 over-hidden column" style="padding: 24px 16px">
      <div class="content flex1 flex">
        <div class="left flex1 column">
          <div class="flex search-bar">
            <div v-if="!currentId">
              <SupplierVersion
                v-model:supplier="supplier"
                v-model:type="type"
                v-model:version="version"
                :presetSupplier="supplier"
                :presetType="type"
                :presetVersion="version"
                :disabled="currentId"
              />
            </div>
            <div class="flex search-bar flex-wrap" v-if="currentId">
              <div class="search-item">设备商：{{ supplier }}</div>
              <div class="search-item">型号：{{ type }}</div>
              <div class="search-item">版本：{{ version }}</div>
            </div>
          </div>
          <Formwork
            class="flex1"
            style="overflow: auto"
            v-model:formwork="localData"
            :asParam="asParam"
          />
        </div>
        <div class="right" id="box">
          {
          <ShowParam :params="localData" />
          }
          <!-- {
            <div class="row" v-for="(param,index) in localData" :key='index'>
              "{{param.name}}": {{param.value?param.value: 'null'}}; <span>//{{param.description}}</span>
            </div>
          } -->
        </div>
      </div>
      <div class="control">
        <button class="blue-button" @click="save">保存</button>
        <button class="empty-button" @click="cancel">取消</button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import Formwork from '@/components/formwork/formwork.vue';
import { ArrowLeftOutlined } from '@ant-design/icons-vue';
import {
  ParamArray,
  ParamBoolean,
  ParamNumber,
  ParamObject,
  ParamString,
} from '@/components/formwork/params/param.model';
import SupplierVersion from '@/components/supplier-version.vue';
import { FormworkDetailModel } from './formwork-detail.model';
import { Presupposition } from '../presupposition-list/presupposition.model';
@Options({
  components: {
    Formwork,
    ArrowLeftOutlined,
    SupplierVersion,
  },
})
export default class FormworkDetail extends Vue {
  // tslint:disable-next-line:variable-name
  public _data: Array<
    ParamString | ParamNumber | ParamBoolean | ParamObject | ParamArray
  > = [new ParamString()];
  public asParam: boolean = false;
  public paramId: string = '';
  public paramName: string = '';
  set localData(
    value: Array<
      ParamString | ParamNumber | ParamBoolean | ParamObject | ParamArray
    >,
  ) {
    this._data = value;
  }
  get localData(): Array<
    ParamString | ParamNumber | ParamBoolean | ParamObject | ParamArray
  > {
    return this._data;
  }
  public currentId: string | undefined;
  public supplier: string = '';
  public type: string = '';
  public version: string = '';
  public mounted() {
    console.log(this.$route.query);
    const param = this.$route.query;
    this.currentId = param.id as string;
    this.asParam = param.asParam === 'true';
    this.paramId = param.paramId as string;
    this.paramName = param.paramName as string;
    if (this.currentId) {
      this.getDetail();
    }
  }
  public async getDetail() {
    console.log('getDetail');
    this.$base.loading.show();
    const a = this.$http
      .get<FormworkDetailModel>(
        `${process.env.VUE_APP_API}/devices/models/${this.currentId}`,
        {},
      )
      .then((r) => {
        console.log(r);
        this.supplier = r.supplier;
        this.type = r.type;
        this.version = r.version;
        this.localData =
          r.model && r.model !== '{}' && r.model !== '[]'
            ? JSON.parse(r.model)
            : [new ParamString()];
        return r;
      })
      .catch((e) => {
        this.$base.error(e);
      });
    if (this.asParam && this.paramId) {
      const b = this.$http.get(
        `${process.env.VUE_APP_API}/devices/preset/${this.paramId}`,
        {},
      );
      const all = await Promise.all([a, b])
        .then((r) => {
          console.log(r);
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      // const all = await Promise.all([a]).then((r) => {
      //   console.log(r);
      // }).catch((e) => {
      //   this.$base.error(e);
      // });
    }
    this.$base.loading.close();
  }
  public async save() {
    this.dealObject(this.localData);
    this.$base.loading.show();
    if (this.currentId) {
      const a = await this.$http
        .put(`${process.env.VUE_APP_API}/devices/models/${this.currentId}`, {
          supplier: this.supplier,
          type: this.type,
          version: this.version,
          model: JSON.stringify(this.localData),
        })
        .then(() => {
          this.$base.success('保存成功');
        })
        .catch((e) => {
          this.$base.error(e);
        });
    } else {
      const a = await this.$http
        .post(`${process.env.VUE_APP_API}/devices/models`, {
          supplier: this.supplier,
          type: this.type,
          version: this.version,
          model: JSON.stringify(this.localData),
        })
        .then(() => {
          this.$base.success('保存成功');
        })
        .catch((e) => {
          this.$base.error(e);
        });
    }
    this.$base.loading.close();
  }

  public preview() {
    console.log(this.localData);
    const box = document.getElementById('box') as HTMLElement;
    const obj = this.dealObject(this.localData);
    this.writeHtml(box, obj);
  }

  public dealObject(
    param: Array<
      | ParamString
      | ParamNumber
      | ParamBoolean
      | ParamObject
      | ParamArray
      | undefined
    >,
    ignore = true,
  ) {
    // console.log('dealObject', param);
    const option: any = {};
    const dealArray = (
      target: string | number | ParamObject | ParamArray | null,
    ): any => {
      // console.log('dealArray', target);
      if (target) {
        if (typeof target === 'string' || typeof target === 'number') {
          return [];
        } else if ((target as any).type === 'object') {
          // console.log('dealArray-object');
          const list: any[] = [];
          (target.list as ParamObject[]).forEach((r) =>
            list.push(this.dealObject([r]), false),
          );
          return list;
        } else if ((target as any).type === 'array') {
          // console.log('dealArray-array');
          const list: any[] = [];
          (target.list as ParamArray[]).forEach((r) => list.push(dealArray(r)));
          return list;
        }
      } else {
        return [];
      }
    };
    param.forEach(
      (
        obj:
          | ParamString
          | ParamNumber
          | ParamBoolean
          | ParamObject
          | ParamArray
          | undefined,
      ) => {
        if (obj) {
          switch (obj.type) {
            case 'object':
              console.log('object');
              if (option[obj.name]) {
                option[obj.name] = this.dealObject(obj.list);
              } else {
                if (!ignore) {
                  this.$base.loading.close();
                  throw this.$base.error('模板不合法');
                }
              }
              break;
            case 'array':
              console.log('object');
              const list: any[] = [];
              (obj as ParamArray).list.forEach((r) => list.push(dealArray(r)));
              console.log('list', list);
              option[obj.name] = list;
              break;
            default:
              if (option[obj.name]) {
                option[obj.name] = obj.value;
              } else {
                if (!ignore) {
                  this.$base.loading.close();
                  throw this.$base.error('模板不合法');
                }
              }
          }
        }
      },
    );
    // console.log(option);
    return option;
  }

  public writeHtml(box: HTMLElement, obj: object | any[], name?: string) {
    // console.log('obj', obj);
    const objStr = JSON.stringify(obj, null, 4);
    // console.log('objStr', objStr);
    const html = objStr.replace(/\n/g, '<br>').replace(/\s/g, '&nbsp');
    // console.log('html', html);
    box.innerHTML = html;
  }
  public cancel() {
    // if (window.opener) {
    //   window.close();
    // } else {
    //   window.location.replace(window.location.origin + '/#/public/formwork');
    // }
    this.$router.back();
  }
}
</script>
<style lang="less" scoped>
.header {
  background: #fff;
  height: 54px;
  line-height: 54px;
  padding: 0px 48px;
  font-weight: 600;
}
.content {
  background: #fff;
  padding-top: 30px;
  .left {
    padding: 0px 30px 10px 40px;
    .search-bar {
      align-items: center;
      margin-bottom: 20px;
      .ant-select {
        margin: 0px 10px 0px 5px;
      }
    }
  }
  .right {
    border-left: dashed 1px #dddddd;
    width: 35%;
    padding: 15px;
    overflow: auto;
  }
}
.control {
  background: #fff;
  padding: 10px 30px 20px 40px;
  .blue-button {
    margin-right: 8px;
  }
}
</style>